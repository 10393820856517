import React, { useContext, useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { PatientpageContext } from '../context/GlobalContext';
import { stringTrim } from '../utility/utility';
import AppointmentCart from './AppointmentCart';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Button from '../Tools/Button';
import { Ls } from '../utility/utility';
import { AppointmentType } from '../utility/config';
import MemoOnline from '../static/svg-component/MemoOnline';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AppointmentBook from './AppointmentBook';
import AppointmentBook1 from './AppointBook1';
import { convertTime24to12 } from '../utility/utility';
import { OPD } from '../calls/api';
import CheckoutScreenNew from './CheckoutScreenNew';
import MemoCash from '../static/svg-component/MemoCash';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Grid } from 'material-ui';
import Patientpro from '../pages/Patient/Patientpro';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';

const AppointmentScreenNew = ({
  toggleAppointment,
  loginMeetNow,
  setLoginMeetNow,
  openAppointmentTab,
}) => {
  const patientContext = useContext(PatientpageContext);
  const [currSlide, setCurrSlide] = useState(0);
  const [appointment, setAppointment] = useState('');
  const history = useHistory();
  const location = useLocation();
  const isOnline = patientContext?.slectedAppointment?.payment_type !== 'cash';
  const isWalkin = appointment === 'Meet Now';
  const isLoggedIn = () => {
    return Ls.get('pt_key') && Ls.get('pt_token');
  };

  const [isSwitchClicked, setIsSwitchClicked] = useState(false);
  const [disableswitchprofile, setDisableswitchprofile] = useState(false);
  const [showSwitchProfileButton, setShowSwitchProfileButton] = useState(true);

  const [showPatientpro, setShowPatientpro] = useState({
    form: false,
    type: 'new',
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = () => {
        window.history.go(1);
        patientContext?.dialogController({
          open: true,
          content: (() => (
            <div className="appointment__screen__exit">
              Do you really wish to exit?
            </div>
          ))(),
          checkLoginPage: false,
          promptWindow: true,
          dialogAction: (() => (
            <div className="appointment__screen__exit__buttons">
              <Button
                handleClick={() => {
                  patientContext.handleDialogClose();
                  toggleAppointment();
                  Ls.remove('pt_key');
                  Ls.remove('pt_token');
                  history.push({
                    pathname: '/',
                    search: history?.location?.search,
                  });
                }}
                className="mr-2"
                containerClass="m-auto appointment__screen__verify"
              >
                EXIT
              </Button>
              <Button
                handleClick={() => {
                  patientContext.handleDialogClose();
                }}
                className="mr-2"
                containerClass="m-auto appointment__screen__verify"
              >
                CANCEL
              </Button>
            </div>
          ))(),
          maxWidth: 'sm',
          disableBackdropClick: true,
        });
      };

      if (!loginMeetNow && !history?.location?.state?.currentSlide) {
        patientContext?.checkNextAvailableSlot();
      }
      // else{
      //   patientContext?.checkNextAvailableSlot();

      // }
    }

    return () => {
      patientContext.setSelectedAppointment({
        ...patientContext.defaultAppointmentData,
        user: patientContext?.slectedAppointment?.user,
        doc: patientContext?.slectedAppointment?.doc,
      });
      if (typeof window !== 'undefined') {
        window.onpopstate = null;
      }
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // console.log(44444444, params.get('loginType'), history?.location?.state)

    if (
      history?.location?.state?.currentSlide &&
      params?.get('loginType') === 'scheduleConsultation'
    ) {
      setDisableswitchprofile(true);

      let url = `/${Ls.get('hosp_id')}/${
        patientContext?.slectedAppointment?.doc?.doc_id
      }/${patientContext?.slectedAppointment?.user?.profile_id}`;
      let data = sessionStorage.getItem('scheduleAppData');
      setLoading(true);

      patientContext
        ?.initiateAppointment(OPD?.bookSlot, url, JSON.parse(data))
        .then((bookedSlot) => {
          if (bookedSlot) {
            patientContext?.setSelectedAppointment({
              ...patientContext?.slectedAppointment,
              bookedSlot: bookedSlot,
              payment_type: 'online',
            });
            setLoading(false);
            setShowSwitchProfileButton(false);

            setCurrSlide(3);
          }
        });
      params.delete('loginType');
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
    }

    const Patientpi = () => {
      if (loginMeetNow) {
        history.push({
          pathname: `/patientpro`,
          search: history?.location?.search,
          state: {
            loginMeetNow: loginMeetNow,
            appointmentData: patientContext?.slectedAppointment,
          },
        });
      } else {
        const params = new URLSearchParams(location.search);
        const loginType = params.get('loginType');

        if (!loginType) {
          // There is no redirection, navigate to AppointmentScreenNew
          history.push({
            pathname: '/consultation', // Update with the correct path
            search: history?.location?.search,
          });
          patientContext?.checkNextAvailableSlot();
        }
      }
    };

    Patientpi();

    if (
      history?.location?.state?.currentSlide &&
      params?.get('loginType') !== 'scheduleConsultation'
    ) {
      let url = `/${Ls.get('hosp_id')}/${
        patientContext?.slectedAppointment?.doc?.doc_id
      }/${patientContext?.slectedAppointment?.user?.profile_id}`;
      patientContext
        ?.initiateAppointment(OPD?.initiateWalkin, url, '')
        .then((res) => {
          if (!res) {
            setCurrSlide(0);
          } else if (res && !res?.appointment_id) {
            res?.message &&
              patientContext?.setState({
                msg: res?.message || '',
                type: 'error',
                timer: null,
                vertical: 'top',
                horizontal: 'center',
              });
            setCurrSlide(0);
          }
        });
      setCurrSlide(2);
      setAppointment('Meet Now');
    }
  }, [loginMeetNow]);

  useEffect(() => {
    if (currSlide === 0) {
      patientContext?.setSelectedAppointment({
        ...patientContext?.slectedAppointment,
        pickedSlot: '',
        bookedSlot: '',
        payment_type: '',
        appointment_date:
          patientContext?.defaultAppointmentData?.appointment_date,
        appointment_type:
          patientContext?.defaultAppointmentData?.appointment_type,
        type: '',
      });
    }
  }, [currSlide]);

  useEffect(() => {
    if (
      patientContext?.slectedAppointment?.doc === '' &&
      patientContext?.slectedAppointment?.user === ''
    ) {
      toggleAppointment();
    }
  }, [
    patientContext?.slectedAppointment?.user,
    patientContext?.slectedAppointment?.doc,
  ]);

  const PaymentButton = ({ children, icon, text }) => {
    return (
      <div className="appointment__screen__payment__card">
        <div className="appointment__screen__payment__svg">{icon}</div>
        <div className="appointment__screen__payment__discount">{text}</div>
        <div className="appointment__screen__payment__button">{children}</div>
      </div>
    );
  };

  const handleType = (e) => {
    const queryString = new URLSearchParams(history.location.search);
    e.preventDefault();
    const name = e.target.id;

    if (name === 'Meet Now') {
      if (!isLoggedIn()) {
        history.push({
          pathname: `/login`,
          search:
            history?.location?.search +
            `&redirectfrom=consultation&btn=meet-now`,
          state: { redirectToPatientpro: true }, // Updated key here
        });
        return;
      }
      setDisableswitchprofile(true);

      let url = `/${Ls.get('hosp_id')}/${
        patientContext?.slectedAppointment?.doc?.doc_id
      }/${patientContext?.slectedAppointment?.user?.profile_id}`;
      patientContext
        ?.initiateAppointment(OPD?.initiateWalkin, url, '')
        .then((res) => {
          if (!res) {
            setCurrSlide(0);
          } else if (res && !res?.appointment_id) {
            res?.message &&
              patientContext?.setState({
                msg: res?.message || '',
                type: 'error',
                timer: null,
                vertical: 'top',
                horizontal: 'center',
              });
            setCurrSlide(0);
          }
        });
      setShowSwitchProfileButton(false);

      // console.log('Setting currSlide to 2');
      setCurrSlide(2);
      setAppointment('Meet Now');
    } else if (name === 'Schedule Consultation') {
      if (!isLoggedIn()) {
        history.push({
          search: history?.location?.search + `&loginType=scheduleConsultation`,
        });
      }
      setShowSwitchProfileButton(false);
      setCurrSlide(1);
      setAppointment('Schedule Consultation');
    } else if (name === 'Book Follow-Up') {
      setCurrSlide(4);
      setAppointment('Book Follow-Up');
    }
    patientContext?.setSelectedAppointment({
      ...patientContext?.slectedAppointment,
      type: name,
    });
  };

  // const handleBack = () => {
  //   setCurrSlide(
  //     currSlide !== 0
  //       ? (appointment === 'Meet Now' && currSlide === 3) ||
  //         (appointment === 'Schedule Consultation' &&
  //           patientContext?.slectedAppointment?.appointment_type ==
  //             AppointmentType?.inperson &&
  //           currSlide === 3)
  //         ? 0
  //         : appointment === 'Schedule Consultation' &&
  //           isOnline &&
  //           patientContext?.slectedAppointment?.appointment_type ==
  //             AppointmentType?.online &&
  //           currSlide === 3
  //         ? 1
  //         : appointment === 'Meet Now' && currSlide === 2
  //         ? 0
  //         : patientContext?.slectedAppointment?.type ===
  //             'Schedule Consultation' &&
  //           patientContext?.slectedAppointment?.appointment_type ===
  //             AppointmentType?.inperson &&
  //           currSlide === 2
  //         ? 1
  //         : currSlide - 1
  //         ? 0
  //         : appointment === 'Book Follow-Up' && currSlide === 4
  //         ? 0 // Set to 1 to go back to "Schedule Consultation"
  //         : currSlide - 1
  //       : 0
  //   );
  // };

  const params = new URLSearchParams(location.search);

  const handleBack = () => {
    if (params.get('loginType') === 'scheduleConsultation' && currSlide == 2) {
      // Logic for handling back after redirection
      setCurrSlide(1); // You might need to set the correct value based on your requirements
    } else if (params.get('loginType') !== 'scheduleConsultation') {
      setCurrSlide(0); // You might need to set the correct value based on your requirements
      patientContext?.checkNextAvailableSlot();
    } else {
      // Original logic for handling back in other cases
      setCurrSlide((prevSlide) => {
        if (
          (appointment === 'Meet Now' && prevSlide === 3) ||
          (appointment === 'Schedule Consultation' &&
            patientContext?.slectedAppointment?.appointment_type ===
              AppointmentType?.inperson &&
            prevSlide === 3) ||
          (appointment === 'Schedule Consultation' &&
            isOnline &&
            patientContext?.slectedAppointment?.appointment_type ===
              AppointmentType?.online &&
            prevSlide === 3) ||
          (appointment === 'Meet Now' && prevSlide === 2) ||
          (patientContext?.slectedAppointment?.type ===
            'Schedule Consultation' &&
            patientContext?.slectedAppointment?.appointment_type ===
              AppointmentType?.inperson &&
            prevSlide === 2) ||
          (appointment === 'Book Follow-Up' && prevSlide === 4)
        ) {
          return 0;
        } else {
          return prevSlide - 1;
        }
      });
    }
  };

  const handleButtonBackClick = () => {
    openAppointmentTab();
  };

  const handleAnchorClick = () => {
    history.push({
      pathname: '/patientpro',
      search: history?.location?.search,
    });
  };

  // console.log(55555555, currSlide)

  useEffect(() => {
    if (history?.location?.state?.currentSlide) {
      setShowSwitchProfileButton(false);
    }
  }, []);

  return showPatientpro?.form ? (
    <Patientpro
      showPatientpro={showPatientpro}
      setShowPatientpro={setShowPatientpro}
      heading=" "
    />
  ) : (
    <div className="appointment__screen">
      {/* <h2>
            <button onClick={handleButtonBackClick}>
              <ChevronLeftIcon />
            </button>
            Home
          </h2> */}

      {!patientContext?.checkoutLoader ? (
        <div className="appointment__screen__close" onClick={toggleAppointment}>
          {/* <CloseIcon /> */}
        </div>
      ) : (
        <></>
      )}
      {currSlide > 0 && !patientContext?.checkoutLoader ? (
        <div className="appointment__screen__back" onClick={handleBack}>
          <ArrowBackIosNewIcon />
        </div>
      ) : (
        <div
          className="appointment__screen__back"
          onClick={handleButtonBackClick}
        >
          <ArrowBackIosNewIcon />
        </div>
      )}
      <div className="appointment__screen__list">
        <div className="appointment__patient">
          {isLoggedIn() && <h6>Booking Appointment for</h6>}
          <div className="appointment__patient__details appointment__screen__user">
            <div className="d-flex align-itmes-center justify-content-center ">
              {isLoggedIn() && (
                <div
                  className={`${
                    showSwitchProfileButton ? 'center-align' : ''
                  } appointment__patient__button__selected appointment__patient__button`}
                >
                  {stringTrim(
                    patientContext?.slectedAppointment?.user?.name,
                    10
                  ) || ''}
                </div>
              )}
              {isLoggedIn() && showSwitchProfileButton && (
                <div
                  className={`text-center pt-2 d-flex justify-content-center cursor-pointer underline-style font-size-15 font-weight-900`}
                >
                  <a
                    style={{
                      color: '#199a8e',
                      textDecoration: 'underline',
                      fontSize: '15px',
                      fontWeight: '900',
                      marginTop: '10px',
                      paddingLeft: '6px',
                    }}
                    onClick={disableswitchprofile ? null : handleAnchorClick}
                    // onClick={() => {
                    //   setShowPatientpro({ form: true, type: 'new' });
                    // }}
                  >
                    <SwitchAccountIcon />
                  </a>
                </div>
              )}
            </div>
          </div>

          {patientContext?.slectedAppointment?.type ===
            'Schedule Consultation' &&
          patientContext?.slectedAppointment?.appointment_type ===
            AppointmentType?.inperson &&
          currSlide === 2 ? (
            <div className="appointment__screen__time">
              {`${convertTime24to12(
                patientContext?.slectedAppointment?.pickedSlot?.slot
              )} -
              ${dayjs(
                patientContext?.slectedAppointment?.appointment_date
              ).format('DD-MMM-YYYY')}`}
            </div>
          ) : (
            <></>
          )}
          <div className="appointment__screen__cart">
            <AppointmentCart
              list={patientContext?.slectedAppointment?.doc}
              handleClick={() => {}}
            />
          </div>
          {currSlide === 0 ? (
            <div className="appointment__screen__type">
              <div
                className={
                  patientContext?.slectedAppointment?.nextslot
                    ?.meet_now_time_alloted === ''
                    ? 'appointment__screen__type__label__red'
                    : 'appointment__screen__type__label__green'
                }
              >
                {patientContext?.slectedAppointment?.nextslot
                  ?.meet_now_message || ''}
              </div>
              <div
                id="Meet Now"
                className={`${
                  !patientContext?.slectedAppointment?.nextslot
                    ?.show_meet_now_button
                    ? 'static_disable'
                    : ''
                } appointment__screen__type__button appointment__screen__meetnow`}
                onClick={(e) => {
                  if (
                    patientContext?.slectedAppointment?.nextslot
                      ?.show_meet_now_button
                  ) {
                    handleType(e);
                  }
                }}
              >
                Meet Now
              </div>

              <label>
                {patientContext?.slectedAppointment?.nextslot
                  ?.meet_now_sub_text || ''}
              </label>
              <div
                id="Schedule Consultation"
                className={`${
                  !patientContext?.slectedAppointment?.nextslot
                    ?.show_schedule_consultation_button
                    ? 'static_disable'
                    : ''
                } appointment__screen__type__button appointment__screen__schedule`}
                onClick={(e) => {
                  if (
                    patientContext?.slectedAppointment?.nextslot
                      ?.show_schedule_consultation_button
                  ) {
                    handleType(e);
                  }
                }}
              >
                Schedule Consultation
              </div>

              <label>
                {patientContext?.slectedAppointment?.nextslot
                  ?.schedule_consultation_sub_text || ''}
              </label>

              {isLoggedIn() && (
                <div
                  className={
                    patientContext?.slectedAppointment?.nextslot
                      ?.show_book_followup_button
                      ? 'appointment__screen__type__label__green'
                      : 'appointment__screen__type__label__red'
                  }
                  style={{ marginTop: '1rem' }}
                >
                  {patientContext?.slectedAppointment?.nextslot
                    ?.followup_message || ''}
                </div>
              )}
              <div
                style={{
                  display: isLoggedIn() ? 'block' : 'none',
                }}
                id="Book Follow-Up"
                className={`${
                  !patientContext?.slectedAppointment?.nextslot
                    ?.show_book_followup_button
                    ? 'static_disable'
                    : ''
                } appointment__screen__type__button appointment__screen__followup`}
                onClick={(e) => {
                  if (
                    patientContext?.slectedAppointment?.nextslot
                      ?.show_book_followup_button
                  ) {
                    handleType(e);
                  }
                }}
              >
                Book Follow-Up
              </div>

              <label>
                {patientContext?.bookflw?.nextslot?.book_followup_sub_text ||
                  ''}
              </label>
            </div>
          ) : (
            <></>
          )}
          {currSlide === 1 ? (
            <AppointmentBook setCurrSlide={setCurrSlide} />
          ) : (
            <></>
          )}
          {currSlide === 4 ? (
            <AppointmentBook1 setCurrSlide={setCurrSlide} />
          ) : (
            <></>
          )}
          {currSlide === 2 ? (
            <div className="appointment__screen__payment">
              <div className="appointment__screen__payment__heading">
                How would you like to make the Payment for the Appointment?
              </div>
              <div className="appointment__screen__payment__subHeading">
                {patientContext?.slectedAppointment?.appointment_type ===
                AppointmentType?.inperson ? (
                  <>
                    Your appointment will be confirmed after receipt of the
                    payment.
                  </>
                ) : (
                  <>
                    You will receive the Token number after successful payment
                  </>
                )}
              </div>
              <div className="appointment__screen__payment__ways">
                <PaymentButton
                  text={
                    <>
                      <strong>
                        Rs.{' '}
                        {
                          patientContext?.slectedAppointment?.bookedSlot
                            ?.online_payment_amount
                        }
                      </strong>
                      {!!patientContext?.slectedAppointment?.bookedSlot
                        ?.online_discount ? (
                        <p>
                          (After{' '}
                          {
                            patientContext?.slectedAppointment?.bookedSlot
                              ?.online_discount
                          }
                          % Discount )
                        </p>
                      ) : (
                        <></>
                      )}
                    </>
                  }
                  icon={<MemoOnline />}
                >
                  <Button
                    // handleClick={() => {
                    //   patientContext?.setSelectedAppointment({
                    //     ...patientContext?.slectedAppointment,
                    //     payment_type: 'online',
                    //   });
                    //   setCurrSlide(3);
                    // }}
                    containerClass=""
                  >
                    Pay Online
                  </Button>
                </PaymentButton>
                <PaymentButton
                  text={
                    <>
                      <strong>
                        Rs.{' '}
                        {
                          patientContext?.slectedAppointment?.bookedSlot
                            ?.online_payment_amount
                        }
                      </strong>
                      {!!patientContext?.slectedAppointment?.doc
                        ?.online_discount ? (
                        <p>(Pay in Full)</p>
                      ) : (
                        <></>
                      )}
                    </>
                  }
                  icon={<MemoCash />}
                >
                  <Button
                    handleClick={() => {
                      patientContext?.setSelectedAppointment({
                        ...patientContext?.slectedAppointment,
                        payment_type: 'cash',
                      });
                      setCurrSlide(3);
                    }}
                    containerClass=""
                  >
                    Pay Cash
                  </Button>
                </PaymentButton>
              </div>
            </div>
          ) : (
            <></>
          )}
          {currSlide === 3 ? (
            <CheckoutScreenNew
              isOnline={isOnline}
              isWalkin={isWalkin}
              toggleAppointment={toggleAppointment}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppointmentScreenNew;
